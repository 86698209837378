<template>
  <div class="w-full lg:w-1/2 px-2 mb-2">
    <div
      class=" mb-5 flex flex-col bg-bg_alt_color rounded-md shadow p-3 h-full"
    >
      <div class="flex items-center mb-3">
        <div>{{ notification.data.title }}</div>
        <div v-if="!isRead" class="w-2 h-2 rounded-full bg-green-500 mx-3"></div>
      </div>
      <div class="font-light">{{ notification.data.body }}</div>
      <div class="flex-auto  flex-shrink-1">
      </div>
      <div class="flex text-xs mt-5">
        <button v-if="!isRead" @click="markAsRead">
          {{ t('mark_as_read') }}
        </button>
        <div class="text-sela-light ltr:ml-auto rtl:mr-auto">{{ createdAt }}</div>
      </div>
      <button
        v-if="notification.type.match('BillAddedToAgentNotification')"
        :aria-label="t('details')"
        class="w-full bg-nav_color rounded-md mt-2 py-1 text-text_alt_color text-center"
        @click="goToDetails"
      >
        {{ t('details') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~~/store/notifications'

const props = defineProps<{ notification: UserNotification }>()

const { t } = useI18n()
const read = ref(false)
const createdAt = new Date(props.notification.created_at).toLocaleDateString()

const isRead = computed(() => {
  if (read.value) {
    return true
  }
  return props.notification.read_at != null
})

async function markAsRead() {
  try {
    await useNuxtApiFetch('/user/notifications/mark-as-read', {
      method: 'POST',
      body: { id: props.notification.id }
    })

    const store = useNotificationStore()

    read.value = true
    store.decreaseUnRead()
  } catch (error) {}
}

function goToDetails() {
  if (!isRead) {
    markAsRead()
  }

  if (props.notification.type.match('BillAddedToAgentNotification')) {
    const router = useRouter()
    const localePath = useLocalePath()
    router.push(
      localePath({
        path: `/my-account/orders/${props.notification.data.id}`
      })
    )
  }
}
</script>
